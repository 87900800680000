@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "ShortcodesUltimateIcons";
  src: url("../public/fonts/forkawesome-webfont.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

:root {
  --font-base: Tahoma, Helvetica, sans-serif;
}

@layer components {
  .odds-site .site-bet-prompt {
    line-height: 0.75rem;
    border: 1px;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .games-sidebar {
    height: calc(100vh - 180px);
  }

  .games-sidebar::-webkit-scrollbar {
    width: 3px;
    background-color: #aaa;
  }

  .games-sidebar::-webkit-scrollbar-thumb {
    background-color: #01337d;
  }

  @media screen and (min-width: 992px) {
    .games-sidebar {
      height: calc(100vh - 250px);
    }
  }

  @keyframes loading {
    0% {
      transform: scaley(1);
    }

    50% {
      transform: scaley(0.4);
    }

    100% {
      transform: scaley(1);
    }
  }

  .loading-state {
    background-color: rgba(12, 17, 43, 0.3);
    width: 4px;
    height: 24px;
    margin: 3px;
    border-radius: 0;
    display: inline-block;
    animation-fill-mode: both;
  }

  .loading-1 {
    animation: loading 1s 0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  .loading-2 {
    animation: loading 1s 0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  .loading-3 {
    animation: loading 1s 0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  .loading-4 {
    animation: loading 1s 0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  .loading-5 {
    animation: loading 1s 0.5s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  .nav-bar-menu-toggle {
    background-color: #01337d;
    clip-path: polygon(100% 0, 100% 23%, 100% 100%, 0 100%, 0 0);
    transition:
      clip-path 0.5s ease,
      background-color 0.5s ease;
  }

  .nav-bar-menu-toggle.active {
    background-color: #012965;
    clip-path: polygon(0 0, 17% 45%, 0% 100%, 100% 100%, 100% 0);
  }

  .nav-bar-picks-toggle {
    background-color: #01337d;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 23%);
    transition:
      clip-path 0.5s ease,
      background-color 0.5s ease;
  }

  .nav-bar-picks-toggle.active {
    background-color: #f4f7fc;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 17% 23%);
  }

  .nav-bar-mobile-menu {
    height: calc(100vh - 56px);
  }

  .nav-bar-caret::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    position: absolute;
    top: 17px;
    right: 0px;
    color: inherit;
  }

  .nav-bar-caret-child::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    position: absolute;
    top: 17px;
    right: 6px;
    color: inherit;
  }

  .nav-bar-caret-mobile-child::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    position: absolute;
    top: 17px;
    right: 12px;
    color: inherit;
  }

  .nav-bar-caret-open::after {
    border-bottom: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-top: 0;
    border-left: 0.3em solid transparent;
  }

  .nav-bar-caret-open-child::after {
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
  }

  .skeleton {
    /* background-color: #eae9f1; */
    display: inline-block;
    height: 450px;
    position: relative;
    overflow: hidden;
    background-color: #dddbdd;
    width: 100%;
  }

  .skeleton::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0) 100%);
    animation: shimmer 2s infinite;
    content: "";
  }

  .trend {
    height: 0;
    width: 0;
    margin: 0 0 0.125rem;
    border: inset 0.5rem;
    vertical-align: middle;
  }

  .trend-over {
    content: "O";
    border-bottom-style: solid;
    border-color: transparent transparent #49ca80;
    border-top-width: 0;
  }

  .trend-under {
    content: "U";
    border-bottom-width: 0;
    border-color: #f86a6a transparent transparent;
    border-top-style: solid;
  }

  .trend-large {
    display: inline-block;
    font-size: 37px;
    width: 50px;
    height: 50px;
    font-weight: 600;
    text-align: center;
    border-radius: 0.5rem;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  .trend-large-over {
    content: "O";
    height: 0;
    width: 0;
    margin: 0 auto 0.25rem;
    border: inset 1rem;
    border-bottom-style: solid;
    border-color: transparent transparent #49ca80;
    border-top-width: 0;
    vertical-align: middle;
  }

  .trend-large-under {
    content: "U";
    height: 0;
    width: 0;
    margin: 0 auto 0.25rem;
    border: inset 1rem;
    border-bottom-width: 0;
    border-color: #f86a6a transparent transparent;
    border-top-style: solid;
    vertical-align: middle;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
}

body {
  color: black;
  font-family: var(--font-base);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

a {
  color: inherit;
  text-decoration: none;
}

/* To hide scrollbar */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.menu-sidebar {
  height: calc(100vh - 280px);
}

.menu-sidebar::-webkit-scrollbar {
  width: 3px;
  background-color: #aaa;
}

.menu-sidebar::-webkit-scrollbar-thumb {
  background-color: #01337d;
}

@media screen and (min-width: 992px) {
  .menu-sidebar {
    height: calc(100vh - 460px);
  }
}

.nogames-sidebar {
  height: calc(100vh - 180px);
}

.nogames-sidebar::-webkit-scrollbar {
  width: 3px;
  background-color: #aaa;
}

.nogames-sidebar::-webkit-scrollbar-thumb {
  background-color: #01337d;
}

@media screen and (min-width: 992px) {
  .nogames-sidebar {
    height: calc(100vh - 164px);
  }
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ellipsis-lg {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.insta-icon {
  padding: 2px 0;
  border-radius: 20%;
  width: 32px !important;
  background: radial-gradient(circle at 33% 100%,
      #fed373 4%,
      #f15245 30%,
      #d92e7f 62%,
      #9b36b7 85%,
      #515ecf);
}

.popover-parent .popover-content {
  display: none;
}

.popover-parent:hover .popover-content {
  display: block;
}

@media (min-width: 1000px) {
  .picks-carousel {
    position: sticky;
    top: 3rem;
    z-index: 1;
  }

  .right-menu {
    top: 25rem;
  }
}

@media (min-width: 1200px) {
  .picks-carousel {
    position: sticky;
    top: 5.5rem;
    z-index: 1;
  }

  .right-menu {
    top: 27.3rem;
  }
}

.cta-button {
  transition: all 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

:target {
  scroll-margin-top: 100px;
}

@media(max-width: 1200px) {
  :target {
    scroll-margin-top: 70px;
  }
}

.video-container {
  position: relative;
  width: 100%;
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .video-container {
    padding-bottom: 56.25%;
    height: 0;
    margin-bottom: 14px;
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: .5rem;
  }
}

@media (min-width: 769px) {
  .video-container iframe {
    width: 100%;
    height: 380px;
    border-radius: .5rem;
  }
}

.item-position {
  background-color: #eae9f2;
  color: #000;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border-top-left-radius: .4rem;
  font-size: 13px;
  padding: 2px;
  font-weight: bold;
}

.border-card {
  border: 1px dashed;
  border-color: #2FBB9C;
}