#bettingnews-content {
  color: #47526e;
  word-break: break-word;

  * {
    max-width: 100%;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1.5rem;
    margin-bottom: 0;
    color: #102132;
  }

  h2 {
    font-size: 1.375rem;
  }

  h3 {
    font-size: 1.125rem;
  }

  h4 {
    font-size: 1rem;
  }

  h5 {
    font-size: 0.7rem;
  }

  h6 {
    font-size: 0.56rem;
  }

  p + h2 {
    margin-top: 1rem;
  }

  p + h3 {
    margin-top: 3rem;
  }

  ul,
  ol {
    margin-left: 0;
    padding-left: 1em;
  }

  ul li::marker,
  ol li::marker {
    color: #2186eb;
    font-weight: bold;
  }

  p,
  ul,
  ol {
    font-size: 1.125rem;
    margin-bottom: 2rem;
    font-family: "Lato", Arial, sans-serif;
  }

  p {
    line-height: 1.815rem;
  }

  ul,
  ol {
    line-height: 1.83rem;
  }

  @media (max-width: 576px) {
    p,
    ul,
    ol {
      line-height: 1.667rem;
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    p {
      line-height: 1.667rem;
    }

    ul,
    ol {
      line-height: 1.83rem;
    }
  }

  img {
    border-radius: 0.5rem;
  }

  .list-group-item p {
    font-size: 1rem;
  }

  .list-group-item::before {
    content: none;
    margin-right: 0;
  }

  figure {
    margin: 24px auto;
    /* next line overrides an inline style of the figure element. */
    width: 100% !important;

    figcaption {
      font-size: 0.7em;
    }
  }

  iframe {
    display: block;
    margin: auto;
  }

  blockquote {
    font-family: "Lato", Arial, sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 1.375rem;
    line-height: 140%;
    color: #1f2933;
  }

  /* a {
    color: rgb(31, 56, 197);
    text-decoration: underline;
  }
  */

  /* Input fields styles */

  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="tel"],
  input[type="number"],
  input[type="date"],
  textarea,
  select {
    display: block;
    padding: 6px 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.5rem;
    outline-color: transparent;
    transition:
      outline-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    margin: 8px 0 4px 0;

    &:focus {
      outline-color: #1f38c5;
    }
  }

  input[type="submit"] {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid #1f38c5;
    padding: 12px 36px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 0.5rem;
    color: #fff;
    background-color: #1f38c5;
  }

  /* WordPress Core Align Classes */

  @media (min-width: 420px) {
    img.aligncenter,
    img.alignleft,
    img.alignright {
      width: auto;
    }

    .aligncenter {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .alignright {
      float: right;
      margin-left: 24px;
    }

    .alignleft {
      float: left;
      margin-right: 24px;
    }
  }

  ul li {
    list-style: disc;
  }

  ol li {
    list-style: decimal;
  }

  .su-spoiler-title {
    color: #102132;
    font-size: 1rem;
    border: 1px solid #dee2e6;
    border-radius: 0.5rem;
    padding-right: 8px;
  }
}
