#bettingnews-content {
  .container,
  .container-fluid {
    padding: 1.625em 15px;
  }

  .sidebar-body::-webkit-scrollbar {
    width: 3px;
    background-color: #aaa;
  }

  .sidebar-body::-webkit-scrollbar-thumb {
    background-color: #01337d;
  }

  .sidebar-body {
    overflow-y: auto;
    height: calc(100% - 105px);
  }

  .sidebar-container {
    width: 100%;
    z-index: 1;
    height: 100%;
    padding: 1.5em 15px 0px 15px;
    background: #f4f7fc;
  }

  .sidebar-wrapper {
    position: fixed;
    top: 56px;
    height: 0;
    width: 100%;
    /* margin-left: -15px; */
    z-index: 1030;
    transition: height 0.5s;
    overflow-y: hidden;
  }

  .sidebar-wrapper.open {
    height: calc(100vh - 56px);
  }

  .sidebar-wrapper.open .sidebar-background {
    width: 100%;
    z-index: -1;
  }

  .main-content {
    min-height: calc(100vh - 200px);
  }

  @media (min-width: 992px) {
    .sidebar-wrapper {
      position: relative;
      display: block;
      transition: none;
      z-index: 0;
      margin-left: 0px;
      width: 100%;
      height: 100%;
      top: 0;
    }

    .sidebar-container {
      width: 100%;
      height: 100%;
      margin-left: 0;
      margin-bottom: 0;
      background: none;
      padding: 3.25em 15px 0px;
    }

    .sidebar-body {
      height: calc(100vh - 250px);
    }

    .left-cta {
      top: 84px !important;
    }
  }

  .trend {
    display: inline-block;
    font-size: 11px;
    width: 15px;
    height: 15px;
    font-weight: 600;
    text-align: center;
    border-radius: 0.5rem;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  .big-trend {
    display: inline-block;
    font-size: 37px;
    width: 50px;
    height: 50px;
    font-weight: 600;
    text-align: center;
    border-radius: 0.5rem;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  .trend-win {
    color: #fff;
    background-color: #49ca80;
  }

  .trend-win:before {
    content: "W";
  }

  .trend-loss {
    color: #fff;
    background-color: #f86a6a;
  }

  .trend-loss:before {
    content: "L";
  }

  .trend-push {
    color: #fff;
    background-color: #7a7b97;
  }

  .trend-push:before {
    content: "P";
  }

  .trend-over {
    content: "O";
    height: 0;
    width: 0;
    margin: 0 0 0.125rem;
    border: inset 0.5rem;
    border-bottom-style: solid;
    border-color: transparent transparent #49ca80;
    border-top-width: 0;
    vertical-align: middle;
  }

  .trend-under {
    content: "U";
    height: 0;
    width: 0;
    margin: 0 0 0.125rem;
    border: inset 0.5rem;
    border-bottom-width: 0;
    border-color: #f86a6a transparent transparent;
    border-top-style: solid;
    vertical-align: middle;
  }

  .big-trend-over {
    content: "O";
    height: 0;
    width: 0;
    margin: 0 auto 0.25rem;
    border: inset 1rem;
    border-bottom-style: solid;
    border-color: transparent transparent #49ca80;
    border-top-width: 0;
    vertical-align: middle;
  }

  .big-trend-under {
    content: "U";
    height: 0;
    width: 0;
    margin: 0 auto 0.25rem;
    border: inset 1rem;
    border-bottom-width: 0;
    border-color: #f86a6a transparent transparent;
    border-top-style: solid;
    vertical-align: middle;
  }

  h1.homepage-title {
    display: inline-block;
    margin-bottom: 8px;
  }

  @media (max-width: 576px) {
    h2,
    .h2 {
      font-size: 1.75rem;
    }
  }

  .embed-container {
    margin: 3em auto;
  }

  .twitter-tweet {
    margin: 2.5em auto !important;
  }

  .social-links,
  .twitter-link {
    float: right;
  }

  .twitter-link span,
  .social-links span {
    color: rgb(123, 135, 148);
    vertical-align: bottom;
  }

  .social-links a svg.svg-inline--fa.fa-w-16,
  .twitter-link a svg.svg-inline--fa.fa-w-16 {
    width: 0.8em;
  }

  a.twitter-icon {
    color: #2d9bf0;
  }

  a.twitch-icon {
    color: #9147ff;
  }

  @media (max-width: 420px) {
    .social-links {
      width: 100%;
      padding-bottom: 1em;
    }

    .social-links span {
      vertical-align: middle;
    }
  }

  .icon-demo-box {
    text-align: center;
  }

  .icon-demo-box .card {
    margin: 0 1rem 2rem;
    display: inline-block;
    padding: 1rem 0 !important;
    width: 120px;
    text-align: center;
    transition: all 0.2s ease-in-out;
  }

  .border-stats {
    border-bottom: 1px solid #fafbfe !important;
  }

  .border-bottom-stats {
    border-bottom: 3px solid #fafbfe !important;
  }

  .bg-yellow-bright {
    background: #f2c94c !important;
  }

  .bg-red-bright {
    background: #fd3f6d !important;
  }

  .bg-blue-300 {
    background: #00a4fe !important;
  }

  .bg-blue-700 {
    background: #03449e;
  }

  .bg-blue-800 {
    background: #01337d;
  }

  .text-blue-700 {
    color: #47a3f3 !important;
  }

  .text-grey-50 {
    color: #f4f5fd;
  }

  .text-grey-100 {
    color: #f4f7fc;
  }

  .text-grey-200 {
    color: #cbd2d9 !important;
  }

  .text-grey-300 {
    color: #9aa5b1 !important;
  }

  .text-grey-400 {
    color: #7b8794 !important;
  }

  .text-grey-500 {
    color: #616e7c !important;
  }

  .text-grey-600 {
    color: #52606d;
  }

  .text-grey-700 {
    color: #3e4c59;
  }

  .text-grey-800 {
    color: #323f4b;
  }

  .text-grey-900 {
    color: #1f2933;
  }

  .text-heading-colour {
    color: #061235;
  }

  .opacity-005 {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }

  .h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
  }

  .h6 {
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
  }

  .latest-block p {
    font-size: 0.875em;
  }

  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
      height: 68px;
    }

    .container,
    .container-fluid {
      padding: 3.25em 15px;
    }
  }

  .short-headline {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #7b8794;
  }

  .card-poppins {
    font-family: var(--font-body);
    font-style: normal;
    letter-spacing: 0.01em;
    padding: 1rem;
  }

  .card-poppins .badge {
    background: #f4f5fd;
    color: #616e7c;
    border-radius: 0.5rem;
    width: 40px;
  }

  .card-poppins .badge.badge-picks-success {
    color: #ffffff;
    background-color: #07818f;
    display: inline-block;
    padding: 0.5em 0.5em;
    width: 40px;
    font-size: 75%;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.5rem;
    -webkit-transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    -webkit-transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    -o-transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    -webkit-transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    -webkit-transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
  }

  .bg-success .text-grey-600 {
    color: #ffffff;
  }

  .round {
    border-radius: 0.5rem;
  }

  .start-time {
    font-size: 0.6875rem;
    font-weight: 600;
    font-family: var(--font-heading);
    color: #5c6571;
  }

  .picks-link {
    font-size: 0.75rem;
    font-weight: 500;
    font-family: var(--font-heading);
    line-height: 1.6;
    letter-spacing: 0.01em;
    color: #0552b5 !important;
  }

  .picks-link img {
    height: 20px;
    margin-right: 8px;
    width: 20px;
  }

  /* ads affiliates */
  .list-group.mt-2.affiliate-top-banner,
  .list-group.mt-2.affiliate-middle-banner-list {
    margin-bottom: 1.5rem !important;
  }

  .affiliate-top-banner img {
    display: inline;
  }

  :root .affiliate-top-banner-btn {
    position: relative;
    top: 15px;
    padding: 0.25rem 0.85rem;
    float: right;
    font-size: 0.81rem;
    line-height: 1.75;
    width: 110px;
    border-radius: 10rem;
  }

  .affiliate-top-banner-cta {
    font-size: 0.85rem;
  }

  @media (min-width: 576px) {
    .list-group.mt-2.affiliate-top-banner {
      margin-top: 1.9rem !important;
      display: none;
    }

    :root .affiliate-top-banner-btn {
      top: 0;
      font-size: 0.85rem;
      width: 125px;
      padding: 0.15rem 0.85rem;
    }

    .affiliate-top-banner-cta {
      display: inline;
      line-height: 2.2;
      font-size: 1rem;
    }
  }

  .table td {
    font-family: var(--font-body), Arial, sans-serif;
  }

  .bg-matchup-menu-1 {
    background: #f4f7fc;
    border: 1px solid #e5e7e8;
    border-radius: 10rem;
  }

  .bg-matchup-menu-1 .btn.btn-link,
  .bg-matchup-menu-2 .btn.btn-link {
    font-size: 0.9375rem;
  }

  .btn.btn-link-selected-1 {
    font-weight: 500;
    font-size: 0.9375rem;
    color: #fff;
    background-color: #01337d;
    border-radius: 10rem;
    border-color: #01337d;
    padding: 0.4375rem 1.25rem;
    text-decoration: none;
  }

  .btn.btn-link-selected-1:hover {
    color: #ffffff;
    background-color: #002159;
    text-decoration: none;
  }

  .btn.btn-link-selected-1:focus,
  .btn.btn-link-selected-1.focus {
    text-decoration: none;
    -webkit-box-shadow: 0 0 1rem rgba(31, 69, 144, 0.27);
    box-shadow: 0 0 1rem rgba(31, 69, 144, 0.27);
  }

  .btn.btn-link-selected-1:disabled,
  .btn.btn-link-selected-1.disabled {
    color: #6c757d;
    pointer-events: none;
  }

  .btn.btn-link-selected-2 {
    font-weight: 500;
    font-size: 0.9375rem;
    color: #01337d;
    /* background-color: #3E4C59;
    border-radius: 10rem;
    border-color: #3E4C59; */
    padding: 0.4375rem 1.25rem;
    text-decoration: underline;
  }

  .btn.btn-link-selected-2:hover {
    text-decoration: underline;
  }

  .btn.btn-link-selected-2:disabled,
  .btn.btn-link-selected-2.disabled {
    color: #6c757d;
    pointer-events: none;
  }

  .block-box {
    background-color: #fff;
    padding: 10px;
    border-radius: 0.5rem;
    margin-bottom: 20px;
    height: 100px;
  }

  .block-long-col {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .block-img {
    max-width: 70px;
  }

  .block-header {
    margin-bottom: 0px !important;
  }

  .block-header a {
    color: #1c75c6;
    font-weight: 500;
  }

  .block-row {
    padding-left: 20px;
    padding-right: 20px;
  }

  h2.block-title {
    color: #fff;
  }

  @media (max-width: 576px) {
    .bg-matchup-menu-1 .btn.btn-link-selected-1,
    .bg-matchup-menu-2 .btn.btn-link-selected-2,
    .bg-matchup-menu-1 .btn.btn-link,
    .bg-matchup-menu-2 .btn.btn-link {
      padding: 0.3125rem 0.75rem;
      font-size: 0.8125rem;
    }
  }

  a.more-link {
    color: #ffffff;
    font-size: 13px;
    line-height: 28px;
  }

  .round-image {
    border-radius: 0.5rem 0.5rem 0px 0px;
  }

  .table.odds-table,
  .table.odds-table td {
    font-family: var(--font-body), Arial, sans-serif;
  }

  .table.odds-table th {
    border-top: 0;
  }

  .table-responsive table.odds-table > tbody > tr > td:nth-of-type(1),
  .table-responsive
    table.odds-table
    > thead
    > tr:nth-of-type(1)
    > th:nth-of-type(1) {
    background-color: #fff;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
  }

  .odds-logo img {
    max-height: 30px;
  }

  .table.odds-table a {
    color: inherit;
  }

  .table.odds-table a:hover {
    color: inherit;
    text-decoration: none;
  }

  .table.odds-table a.bet-link:hover:after {
    background-color: #47a3f3;
    color: #fff;
    content: "Bet Now";
    align-items: center;
    display: flex;
    font-weight: bold;
    font-size: 12px;
    height: 100%;
    justify-content: center;
    left: 0;
    text-decoration: none;
    transition: all 100ms ease-in-out;
    z-index: 2;
  }

  @media (max-width: 576px) {
    .odds-table.table th,
    .odds-table.table td {
      padding: 0.5rem;
    }

    .odds-logo span img {
      max-height: 20px;
    }
  }

  .featured-img {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .featured-img-container-50 {
    width: 100%;
    padding-bottom: 50%;
    position: relative;
  }

  .featured-img-container-60 {
    width: 100%;
    padding-bottom: 60%;
    position: relative;
  }

  .su-service-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-content .su-spoiler-title {
    font-size: 1rem;
    border: 1px solid #dee2e6;
    border-radius: 0.5rem;
  }

  .main-content .su-service-title i {
    text-align: center;
    font-size: 2rem;
    line-height: 1em;
  }

  .su-spoiler-icon-plus.su-spoiler-closed .su-spoiler-icon:before {
    color: #7cc4fa;
  }

  .su-service-content,
  .su-spoiler-content {
    font-family: var(--font-body), Arial, sans-serif;
    font-size: 1.125rem;
    line-height: 1.815rem;
  }

  @media (max-width: 576px) {
    .su-service-content,
    .su-spoiler-content {
      font-family: var(--font-body), Arial, sans-serif;
      line-height: 1.667rem;
      font-size: 1rem;
    }

    .su-service-content {
      padding-left: 0px !important;
    }
  }

  .cta-logo {
    width: 194px;
    height: 62px;
    object-fit: contain;
  }

  summary {
    font-size: 1.125rem;
    font-weight: 500;
    background-color: #fff;
    color: #102132;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    border: 1px solid #eae9f2;
    border-radius: 0.5rem;
    text-align: left;
    cursor: pointer;
    position: relative;
  }

  details > summary::after {
    position: absolute;
    content: "+";
    right: 20px;
  }

  details[open] > summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  details > summary::marker {
    display: none;
  }

  details[open] summary ~ * {
    animation: sweep 0.5s ease-in-out;
  }

  .home-list-image {
    width: 80px;
    height: 80px;
  }

  .home-list-image img {
    width: 80px;
    height: 80px;
  }

  .custom-content h2,
  .custom-content .custom-content h3,
  .custom-content h4,
  .custom-content .custom-content h5,
  .custom-content h6 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    color: #102132;
  }

  .custom-content p + h2 {
    margin-top: 3rem;
  }

  .custom-content p + h3 {
    margin-top: 3rem;
  }

  .custom-content ul,
  .custom-content ol {
    margin-left: 0;
    padding-left: 1em;
  }

  .custom-content ul li::marker,
  .custom-content ol li::marker {
    color: #2186eb;
    font-weight: bold;
  }

  .custom-content p,
  .custom-content ul,
  .custom-content ol {
    font-size: 1.125rem;
    margin-bottom: 2rem;
    font-family: var(--font-body), Arial, sans-serif;
  }

  .custom-content p {
    line-height: 1.815rem;
  }

  .custom-content ul,
  .custom-content ol {
    line-height: 1.83rem;
  }

  /* Sub Nav */
  .subnav {
    float: left;
    overflow: hidden;
  }

  .subnav-content {
    display: none;
    position: absolute;
    left: 0;
    background-color: #0552b5;
    width: 100%;
    z-index: 1;
  }

  .subnav-content a {
    float: left;
  }

  .subnav:hover .subnav-content {
    display: block;
  }

  .subnav-search {
    width: 100%;
    max-width: 500px;
    min-width: 200px;
    background-color: hsl(0, 0%, 100%);
    border-color: #cbd2d9;
    border-radius: 0.5rem;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    height: 44px;
    padding: 5px 16px;
    line-height: 21px;
    font-family: var(--font-body), Arial, sans-serif;
    font-size: 16px !important;
    font-weight: normal !important;
  }

  @media (max-width: 576px) {
    .custom-content p,
    .custom-content ul,
    .custom-content ol {
      line-height: 1.667rem;
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }

    .custom-content p {
      line-height: 1.667rem;
    }

    .custom-content ul,
    .custom-content ol {
      line-height: 1.83rem;
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px;
    }

    100% {
      opacity: 1;
      margin-top: 0px;
    }
  }

  .home-card {
    min-height: 118px;
  }

  .pagination {
    font-family: var(--font-body), Arial, sans-serif;
  }

  .w-65 {
    width: 65% !important;
  }

  .w-40 {
    width: 40% !important;
  }

  .w-35 {
    width: 35% !important;
  }

  .w-33 {
    width: 33% !important;
  }

  .w-30 {
    width: 30% !important;
  }

  .w-20 {
    width: 20% !important;
  }

  .w-15 {
    width: 15% !important;
  }

  #basic-navbar-nav {
    font-size: 1em;
    letter-spacing: 0.03em;
  }

  @media (max-width: 1200px) and (min-width: 991px) {
    #basic-navbar-nav {
      font-size: 0.925em;
    }
  }

  .sidebar-row > * {
    flex-basis: 40px;
    flex-grow: 0;
  }

  .badge-grey {
    color: #616e7c;
    background-color: #f4f5fd;
  }

  .badge-grey-2 {
    background: #f4f5fd;
    color: #616e7c;
  }

  .left-cta {
    z-index: 1010 !important;
  }

  .left-cta-box {
    background-color: #fdfdff;
    border: 1px solid #eae9f2;
    border-radius: 0.5rem;
  }

  .bg-left-cta-box {
    border-radius: 0.5rem 0px 0px 0.5rem;
    min-width: 160px;
  }

  .bg-fanduel {
    background-color: #256afe;
  }

  .bg-betmgm {
    background-color: #000000;
  }

  .bg-unibet {
    background-color: #000000;
  }

  .bg-sugarhouse {
    background-color: #286193;
  }

  .bg-betrivers {
    background-color: #18355e;
  }

  .bg-pointsbet {
    background-color: #000000;
  }

  .bg-caesars {
    background-color: #173432;
  }

  .bg-drf {
    background-color: #ed2424;
  }

  .heading-2-line {
    @media (min-width: 1024px) {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      display: -moz-box;
      -moz-box-orient: vertical;

      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
